<template>
  <a-modal
    class="upload-example"
    :visible="visible"
    title="样品图片"
    :width="300"
    @cancel="handleCancel"
  >
    <div>
      <div class="upload-div">
        <div class="label">上传文件</div>
        <!--   accept=".jpg,.jpeg,.mp4"   -->
        <a-upload :showUploadList="false" :customRequest="handleFileUpload">
          <a-button> <a-icon type="upload" /> 上传文件 </a-button>
        </a-upload>
      </div>
      <div class="swiper-images">
        <div v-for="(item, index) in fileList" :key="item">
          <div class="pic">
            <!--                <my-image :imgWidth="80" imgUrl="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" />-->
            <my-image :imgWidth="80" v-if="item" :imgUrl="item" />
            <!--            <div class="fa fa-window-close" @click.stop="delImg(item)"> X </div>-->
            <a-icon type="close" class="fa-window-close" @click.stop="delImg(index)" />
          </div>
        </div>
      </div>
    </div>

    <template slot="footer" class="footer-btns">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" :loading="posting" @click="handleModalOkClick">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api';
import { generateUUID } from '@/utils';
import axios from 'axios';
import { getOssToken } from '@/service/user';
import { uploadSampleUrls } from '@/service/merchant';
import { DeleteOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  props: {
    // 是否显示弹框
    visible: {
      type: Boolean,
      default: false,
    },
    detailData: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    DeleteOutlined,
  },
  setup(props, ctx) {
    const posting = ref(false);
    const fileList = ref([]);
    fileList.value = JSON.parse(JSON.stringify(props.detailData.sample_urls || []));

    const handleModalOkClick = async () => {
      console.log('----');
      posting.value = true;
      const {
        res: { success, message },
      } = await uploadSampleUrls(props.detailData.id, {
        sample_urls: fileList.value,
      });
      posting.value = false;
      if (success) {
        ctx.root.$message.success('上传样品图片成功');
        ctx.emit('reload');
        ctx.emit('closeClick');
      } else {
        ctx.root.$message.error(message || '上传失败');
      }
    };
    const oss = ref('');
    // 获取osstoken
    const getNewOssToken = async () => {
      const { err, res } = await getOssToken();
      if (!err && res.success) {
        oss.value = res.data;
      }
    };
    getNewOssToken();
    // 文件上传方法
    const handleFileUpload = async file => {
      if (!oss.value || oss.value.expire < new Date().getTime().toString().substring(0, 10))
        await getNewOssToken();
      if (!oss.value) {
        ctx.root.$message.warn('上传失败');
        return false;
      }
      console.log('---', oss.value);
      const formData = new FormData();
      const pathArr = file.file.name.split('.');
      const key = `${oss.value.dir}douyin-goods/${generateUUID()}.${pathArr[pathArr.length - 1]}`;
      formData.append('key', key);
      formData.append('policy', oss.value.policy);
      formData.append('signature', oss.value.signature);
      formData.append('success_action_status', '200');
      formData.append('OSSAccessKeyId', oss.value.accessid);
      formData.append('file', file.file);

      axios({
        method: 'post',
        url: oss.value.host,
        data: formData,
      }).then(async response => {
        fileList.value.push(response.config.url + '/' + key);
      });
    };
    const delImg = index => {
      const arr = fileList.value || [];
      arr.splice(index, 1);
      fileList.value = arr;
    };
    return {
      delImg,
      fileList,
      handleFileUpload,
      posting,
      handleModalOkClick,
    };
  },

  methods: {
    // 关闭弹框
    handleCancel() {
      this.$emit('closeClick');
    },
  },
});
</script>
<style lang="less">
.upload-example {
  .ant-modal {
    margin-top: 15vh;
  }
  .ant-modal-body {
    min-height: 150px;
    max-height: 300px;
  }
  .upload-div {
    display: flex;
    .label {
      width: 70px;
      line-height: 32px;
    }
  }
  .swiper-images {
    margin-top: 12px;
    width: 240px;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 12px;
    > div {
      margin-right: 12px;
      border: 1px solid #f6f6f6;
      img {
        height: 80px;
        width: 80px;
      }
    }
    .pic {
      position: relative;
      .fa-window-close {
        width: 20px;
        height: 20px;
        line-height: 19px;
        position: absolute;
        top: -8px;
        right: -8px;
        color: #666;
        font-size: 11px;
        z-index: 99;
        border: 1px solid lightgray;
        border-radius: 20px;
        background-color: whitesmoke;
        &:hover {
          background-color: #e83741;
          color: #ffffff;
        }
      }
    }
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
    button + button {
      margin-left: 12px;
    }
  }
}
</style>
